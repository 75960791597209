@import "components/searchbar";

header {
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.1);
  z-index: 100;

  .searchbar {
    input {
      background-color: transparent !important;
      border: none !important;
      box-shadow:none !important;
      color: theme-color('secondary');

      &::placeholder {
        color: theme-color('light');
      }
    }
  }
}