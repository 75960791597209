.page.edit-investor {

  ul.nav {
    font-size: $font-size-base * 1.2;
  }

  .row.active {
    display: flex !important;
  }

  form {
    max-height: 100%;

    // form layout

    .form-control-bar {
      z-index: 20;
    }

    .revenue-form-set {
      .revenue-form {
        transition: all .15s ease;

        &:hover {
          background-color: lighten(theme-color('light'), 30%);
        }
      }
    }

    .exchange-rate-field {
      &.disabled {
        opacity: .4;
      }
    }

    ul.errors {
      padding-left: 0;
      list-style-type: none;
      display: none;
    }
    ul.errors li {
      color: theme-color('danger');
      font-size: .8rem;
    }
    .in-error .errors {
      display: block;
    }

    // widgets

    .widget {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      .label {
        margin-bottom: .4rem;
      }

      input, textarea {
        box-shadow: none !important;
        outline: none !important;
      }
      
      input::placeholder {
        color: theme-color('light');
        opacity: .8;
      }
      &.locked {
        input::placeholder {
          color: #6c757d;
        }
      }
    }

    .in-error .widget input[name] {
      background-color: lighten(theme-color('danger'), 30%) !important;
    }

    .text-widget {
      .user {
        position: relative;
        input {
          border: none !important;
          background-color: transparent !important;
          width: 100%;
        }
      }
      &.locked {
        .user {
          padding-left: 2 * $font-size-base;
          &:before {
            content: "\00a0";
            position: absolute;
            left: $font-size-base;
            top: 50%;
            transform: translateY(-50%);
            color: theme-color('light');
            color: #6c757d; // FIXME theme-color('muted') does not expand
            padding-left: 1.4rem;
            background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iOHB4IiBoZWlnaHQ9IjExcHgiIHZpZXdCb3g9IjAgMCA4IDExIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IHNrZXRjaHRvb2wgNDguMiAoNDczMjcpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPjI5MDJFMjM3LTE0RTktNEQzQS05MjFELTkyODlENkVDQkU5MTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggc2tldGNodG9vbC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iYXYwM0MyX0NyZWF0ZV9zdGFydHVwX2ZpbGxlZCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEwODYuMDAwMDAwLCAtNzEzLjAwMDAwMCkiIG9wYWNpdHk9IjAuNjAwMDAwMDI0Ij4KICAgICAgICA8ZyBpZD0iQ29udGVudCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzIwLjAwMDAwMCwgMTIwLjAwMDAwMCkiPgogICAgICAgICAgICA8ZyBpZD0ic3RhcnR1cF9pbmZvcyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC4wMDAwMDAsIDY4LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9InJvdyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzAuMDAwMDAwLCA0NjcuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTE0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3MTYuMDAwMDAwLCAzOS4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICAgICAgPGcgaWQ9Imljbl9pbnB1dF9sb2NrZWQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIwLjAwMDAwMCwgMTYuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlIiBmaWxsPSIjOTc5REFEIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHg9IjAiIHk9IjgiIHdpZHRoPSI4IiBoZWlnaHQ9IjYiIHJ4PSIxIj48L3JlY3Q+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMiw5IEwyLDYgQzIsNC44OTU0MzA1IDIuODk1NDMwNSw0IDQsNCBMNCw0IEM1LjEwNDU2OTUsNCA2LDQuODk1NDMwNSA2LDYgTDYsOSIgaWQ9IlJlY3RhbmdsZS0yIiBzdHJva2U9IiM5NzlEQUQiIHN0cm9rZS13aWR0aD0iMiI+PC9wYXRoPgogICAgICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=') 0 30% /auto 1rem no-repeat;
          }
        }
      }
    }

    .select-widget {
      position: relative;

      a.dropdown-toggle {
        outline: none !important;
        text-decoration: none !important;
        position: relative;
        &:after {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 30px;
        }
        &[value=''] {
          font-style: italic;
        }
      }
      .dropdown-menu {
        width: 100%;
      }
      a.dropdown-item[value=''] {
        font-style: italic;
      }
    }
    .in-error {
      .select-widget {
        a.dropdown-toggle {
          background-color: lighten(theme-color('danger'), 30%) !important;
        }
      }
    }

    .percentage-widget {
      .user {
        position: relative;
        padding-right: 2 * $font-size-base;
        &:after {
          content: '%';
          position: absolute;
          right: $font-size-base;
          top: 50%;
          transform: translateY(-50%);
          color: theme-color('light');
        }
        input {
          border: none !important;
          background-color: transparent !important;
        }
      }

      &.locked {
        .user {
          padding-left: 2 * $font-size-base;
          &:before {
            content: "\00a0";
            position: absolute;
            left: $font-size-base;
            top: 50%;
            transform: translateY(-50%);
            color: theme-color('light');
            color: #6c757d; // FIXME theme-color('muted') does not expand
            padding-left: 1.4rem;
            background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iOHB4IiBoZWlnaHQ9IjExcHgiIHZpZXdCb3g9IjAgMCA4IDExIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IHNrZXRjaHRvb2wgNDguMiAoNDczMjcpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPjI5MDJFMjM3LTE0RTktNEQzQS05MjFELTkyODlENkVDQkU5MTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggc2tldGNodG9vbC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iYXYwM0MyX0NyZWF0ZV9zdGFydHVwX2ZpbGxlZCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEwODYuMDAwMDAwLCAtNzEzLjAwMDAwMCkiIG9wYWNpdHk9IjAuNjAwMDAwMDI0Ij4KICAgICAgICA8ZyBpZD0iQ29udGVudCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzIwLjAwMDAwMCwgMTIwLjAwMDAwMCkiPgogICAgICAgICAgICA8ZyBpZD0ic3RhcnR1cF9pbmZvcyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC4wMDAwMDAsIDY4LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9InJvdyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzAuMDAwMDAwLCA0NjcuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTE0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3MTYuMDAwMDAwLCAzOS4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICAgICAgPGcgaWQ9Imljbl9pbnB1dF9sb2NrZWQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIwLjAwMDAwMCwgMTYuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlIiBmaWxsPSIjOTc5REFEIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHg9IjAiIHk9IjgiIHdpZHRoPSI4IiBoZWlnaHQ9IjYiIHJ4PSIxIj48L3JlY3Q+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMiw5IEwyLDYgQzIsNC44OTU0MzA1IDIuODk1NDMwNSw0IDQsNCBMNCw0IEM1LjEwNDU2OTUsNCA2LDQuODk1NDMwNSA2LDYgTDYsOSIgaWQ9IlJlY3RhbmdsZS0yIiBzdHJva2U9IiM5NzlEQUQiIHN0cm9rZS13aWR0aD0iMiI+PC9wYXRoPgogICAgICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=') 0 30% /auto 1rem no-repeat;
          }
          &:after {
            color: #6c757d;
          }
        }
      }
    }

    .unit-widget {
      .user {
        position: relative;
        input {
          border: none !important;
          background-color: transparent !important;
        }
      }

      &.locked {
        .user {
          padding-left: 2 * $font-size-base;
          &:before {
            content: "\00a0";
            position: absolute;
            left: $font-size-base;
            top: 50%;
            transform: translateY(-50%);
            color: theme-color('light');
            color: #6c757d; // FIXME theme-color('muted') does not expand
            padding-left: 1.4rem;
            background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iOHB4IiBoZWlnaHQ9IjExcHgiIHZpZXdCb3g9IjAgMCA4IDExIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IHNrZXRjaHRvb2wgNDguMiAoNDczMjcpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPjI5MDJFMjM3LTE0RTktNEQzQS05MjFELTkyODlENkVDQkU5MTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggc2tldGNodG9vbC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iYXYwM0MyX0NyZWF0ZV9zdGFydHVwX2ZpbGxlZCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEwODYuMDAwMDAwLCAtNzEzLjAwMDAwMCkiIG9wYWNpdHk9IjAuNjAwMDAwMDI0Ij4KICAgICAgICA8ZyBpZD0iQ29udGVudCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzIwLjAwMDAwMCwgMTIwLjAwMDAwMCkiPgogICAgICAgICAgICA8ZyBpZD0ic3RhcnR1cF9pbmZvcyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC4wMDAwMDAsIDY4LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9InJvdyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzAuMDAwMDAwLCA0NjcuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTE0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3MTYuMDAwMDAwLCAzOS4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICAgICAgPGcgaWQ9Imljbl9pbnB1dF9sb2NrZWQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIwLjAwMDAwMCwgMTYuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlIiBmaWxsPSIjOTc5REFEIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHg9IjAiIHk9IjgiIHdpZHRoPSI4IiBoZWlnaHQ9IjYiIHJ4PSIxIj48L3JlY3Q+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMiw5IEwyLDYgQzIsNC44OTU0MzA1IDIuODk1NDMwNSw0IDQsNCBMNCw0IEM1LjEwNDU2OTUsNCA2LDQuODk1NDMwNSA2LDYgTDYsOSIgaWQ9IlJlY3RhbmdsZS0yIiBzdHJva2U9IiM5NzlEQUQiIHN0cm9rZS13aWR0aD0iMiI+PC9wYXRoPgogICAgICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=') 0 30% /auto 1rem no-repeat;
          }
        }
      }
    }

    .number-widget {
      .user {
        position: relative;
        input {
          border: none !important;
          background-color: transparent !important;
        }
      }

      &.locked {
        .user {
          padding-left: 2 * $font-size-base;
          &:before {
            content: "\00a0";
            position: absolute;
            left: $font-size-base;
            top: 50%;
            transform: translateY(-50%);
            color: theme-color('light');
            color: #6c757d; // FIXME theme-color('muted') does not expand
            padding-left: 1.4rem;
            background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iOHB4IiBoZWlnaHQ9IjExcHgiIHZpZXdCb3g9IjAgMCA4IDExIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IHNrZXRjaHRvb2wgNDguMiAoNDczMjcpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPjI5MDJFMjM3LTE0RTktNEQzQS05MjFELTkyODlENkVDQkU5MTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggc2tldGNodG9vbC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iYXYwM0MyX0NyZWF0ZV9zdGFydHVwX2ZpbGxlZCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEwODYuMDAwMDAwLCAtNzEzLjAwMDAwMCkiIG9wYWNpdHk9IjAuNjAwMDAwMDI0Ij4KICAgICAgICA8ZyBpZD0iQ29udGVudCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzIwLjAwMDAwMCwgMTIwLjAwMDAwMCkiPgogICAgICAgICAgICA8ZyBpZD0ic3RhcnR1cF9pbmZvcyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC4wMDAwMDAsIDY4LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9InJvdyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzAuMDAwMDAwLCA0NjcuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTE0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3MTYuMDAwMDAwLCAzOS4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICAgICAgPGcgaWQ9Imljbl9pbnB1dF9sb2NrZWQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIwLjAwMDAwMCwgMTYuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlIiBmaWxsPSIjOTc5REFEIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHg9IjAiIHk9IjgiIHdpZHRoPSI4IiBoZWlnaHQ9IjYiIHJ4PSIxIj48L3JlY3Q+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMiw5IEwyLDYgQzIsNC44OTU0MzA1IDIuODk1NDMwNSw0IDQsNCBMNCw0IEM1LjEwNDU2OTUsNCA2LDQuODk1NDMwNSA2LDYgTDYsOSIgaWQ9IlJlY3RhbmdsZS0yIiBzdHJva2U9IiM5NzlEQUQiIHN0cm9rZS13aWR0aD0iMiI+PC9wYXRoPgogICAgICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=') 0 30% /auto 1rem no-repeat;
          }
        }
      }
    }

    .coeff-widget {
      .user {
        position: relative;
        padding-right: 2 * $font-size-base;
        &:after {
          content: 'x';
          position: absolute;
          right: $font-size-base;
          top: 50%;
          transform: translateY(-50%);
          color: theme-color('light');
        }
        input {
          border: none !important;
          background-color: transparent !important;
        }
      }

      &.locked {
        .user {
          padding-left: 2 * $font-size-base;
          &:before {
            content: "\00a0";
            position: absolute;
            left: $font-size-base;
            top: 50%;
            transform: translateY(-50%);
            color: theme-color('light');
            color: #6c757d; // FIXME theme-color('muted') does not expand
            padding-left: 1.4rem;
            background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iOHB4IiBoZWlnaHQ9IjExcHgiIHZpZXdCb3g9IjAgMCA4IDExIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IHNrZXRjaHRvb2wgNDguMiAoNDczMjcpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPjI5MDJFMjM3LTE0RTktNEQzQS05MjFELTkyODlENkVDQkU5MTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggc2tldGNodG9vbC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iYXYwM0MyX0NyZWF0ZV9zdGFydHVwX2ZpbGxlZCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEwODYuMDAwMDAwLCAtNzEzLjAwMDAwMCkiIG9wYWNpdHk9IjAuNjAwMDAwMDI0Ij4KICAgICAgICA8ZyBpZD0iQ29udGVudCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzIwLjAwMDAwMCwgMTIwLjAwMDAwMCkiPgogICAgICAgICAgICA8ZyBpZD0ic3RhcnR1cF9pbmZvcyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC4wMDAwMDAsIDY4LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9InJvdyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzAuMDAwMDAwLCA0NjcuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTE0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3MTYuMDAwMDAwLCAzOS4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICAgICAgPGcgaWQ9Imljbl9pbnB1dF9sb2NrZWQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIwLjAwMDAwMCwgMTYuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlIiBmaWxsPSIjOTc5REFEIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHg9IjAiIHk9IjgiIHdpZHRoPSI4IiBoZWlnaHQ9IjYiIHJ4PSIxIj48L3JlY3Q+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMiw5IEwyLDYgQzIsNC44OTU0MzA1IDIuODk1NDMwNSw0IDQsNCBMNCw0IEM1LjEwNDU2OTUsNCA2LDQuODk1NDMwNSA2LDYgTDYsOSIgaWQ9IlJlY3RhbmdsZS0yIiBzdHJva2U9IiM5NzlEQUQiIHN0cm9rZS13aWR0aD0iMiI+PC9wYXRoPgogICAgICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=') 0 30% /auto 1rem no-repeat;
          }
          &:after {
            color: #6c757d;
          }
        }
      }
    }

    .choice-widget {
      position: relative;

      .user {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        
        a.btn-choice {
          background-color: $bg-color-faded-blue;
          color: $color-dark-blue;
          border-radius: 1rem;
          padding: $font-size-base * 0.4 $font-size-base * 0.8;
          margin-right: $font-size-base * 0.5;
          margin-top: $font-size-base * 0.2;
          border: 1px solid transparent;
          display: inline-block;
          white-space: nowrap;
          text-decoration: none !important;
          &:hover {
            background-color: $color-faded-blue;
            color: white;
          }
          &.active {
            background-color: $color-dark-blue !important;
            color: white;
          }
        }
      }
    }

    .multichoice-widget {
      position: relative;

      .user {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        
        a.btn-choice {
          background-color: $bg-color-faded-blue;
          color: $color-dark-blue;
          border-radius: 1rem;
          padding: $font-size-base * 0.4 $font-size-base * 0.8;
          margin-right: $font-size-base * 0.5;
          margin-top: $font-size-base * 0.2;
          border: 1px solid transparent;
          display: inline-block;
          white-space: nowrap;
          text-decoration: none !important;
          &:hover {
            background-color: $color-faded-blue;
            color: white;
          }
          &.active {
            background-color: $color-dark-blue !important;
            color: white;
          }
        }
      }
    }

    .autocomplete-widget {
      position: relative;

      .user {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .item {
          flex: 0 0 auto;
          background-color: $bg-color-faded-blue;
          color: $color-dark-blue;
          border-radius: 1rem;
          padding: $font-size-base * 0.2 $font-size-base * 0.4;
          margin-right: $font-size-base * 0.5;
          margin-top: $font-size-base * 0.2;
          margin-bottom: $font-size-base * 0.2;
          border: 1px solid transparent;
          display: inline-block;
          white-space: nowrap;
          a {
            text-decoration: none !important;
            margin-left: .5rem;
            &:hover {
              font-weight: bold;
            }
          }
        }

        input {
          flex: 1 1 auto;
          border: none;
          background-color: transparent;
        }
      }

      .autocomplete-list {
        // position: absolute;
        // top: 100%;
        // left: 0;
        width: 100%;
        // display: none;
        background-color: white;
        z-index: 30;
        border-top: none !important;

        &.active {
          display: block;

        }
      }
    }

    .money-widget {
      .user {
        position: relative;
        padding-left: 2 * $font-size-base;
        &:before {
          content: '€';
          position: absolute;
          left: $font-size-base;
          top: 50%;
          transform: translateY(-50%);
          color: theme-color('light');
        }
        input {
          border: none !important;
          background-color: transparent !important;
        }
      }

      &[currency="EUR"] {
        .user.input {
          display: none;
        }
      }
      &[currency="USD"] {
        .user.input:before {
          content: '$';
        }
      }
      &[currency="GBP"] {
        .user.input:before {
          content: '£';
        }
      }

      &.locked {
        .user {
          padding-left: 3 * $font-size-base;
          &:before {
            color: #6c757d; // FIXME theme-color('muted') does not expand
            padding-left: 1.4rem;
            background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iOHB4IiBoZWlnaHQ9IjExcHgiIHZpZXdCb3g9IjAgMCA4IDExIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IHNrZXRjaHRvb2wgNDguMiAoNDczMjcpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPjI5MDJFMjM3LTE0RTktNEQzQS05MjFELTkyODlENkVDQkU5MTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggc2tldGNodG9vbC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iYXYwM0MyX0NyZWF0ZV9zdGFydHVwX2ZpbGxlZCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEwODYuMDAwMDAwLCAtNzEzLjAwMDAwMCkiIG9wYWNpdHk9IjAuNjAwMDAwMDI0Ij4KICAgICAgICA8ZyBpZD0iQ29udGVudCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzIwLjAwMDAwMCwgMTIwLjAwMDAwMCkiPgogICAgICAgICAgICA8ZyBpZD0ic3RhcnR1cF9pbmZvcyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC4wMDAwMDAsIDY4LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9InJvdyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzAuMDAwMDAwLCA0NjcuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTE0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3MTYuMDAwMDAwLCAzOS4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICAgICAgPGcgaWQ9Imljbl9pbnB1dF9sb2NrZWQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIwLjAwMDAwMCwgMTYuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlIiBmaWxsPSIjOTc5REFEIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHg9IjAiIHk9IjgiIHdpZHRoPSI4IiBoZWlnaHQ9IjYiIHJ4PSIxIj48L3JlY3Q+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMiw5IEwyLDYgQzIsNC44OTU0MzA1IDIuODk1NDMwNSw0IDQsNCBMNCw0IEM1LjEwNDU2OTUsNCA2LDQuODk1NDMwNSA2LDYgTDYsOSIgaWQ9IlJlY3RhbmdsZS0yIiBzdHJva2U9IiM5NzlEQUQiIHN0cm9rZS13aWR0aD0iMiI+PC9wYXRoPgogICAgICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=') 0 30% /auto 1rem no-repeat;
          }
        }
        .user.input {
          display: none;
        }
        .user.converted {
          display: block;
        }
      }
    }

    .checkbox-widget {
      input[type="checkbox"] {
        position: absolute;
        margin: 8px 0 0 16px;
        left: -100000px;
        top: -100px;
      }
      input[type="checkbox"] + label {
        position: relative;
        padding: 0 0 0 50px;
        line-height: 1.4em;
        color: $text-orange;
        font-weight: bold;
        margin-bottom: 0.7rem;
        margin-top: 0.4rem;
        .yes {
          display: none;
        }
        .no {
          display: inline;
        }
      }
      input[type="checkbox"] + label:before {
        content: "";
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        width: 40px; /* x*5 */
        height: 24px; /* x*3 */
        border-radius: 16px; /* x*2 */
        background: #fff;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        background: $text-orange;
      }
      input[type="checkbox"] + label:after {
        content: "";
        position: absolute;
        display: block;
        left: 4px;
        top: 4px;
        width: 16px; /* x*3 */
        height: 16px; /* x*3 */
        border-radius: 16px; /* x*2 */
        background: #fff;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
      }
      input[type="checkbox"] + label:hover:after {
        box-shadow: 0 0 5px rgba(0,0,0,0.3);
      }
      &.locked input[type="checkbox"] + label:hover:after {
        box-shadow: none;
      }
      input[type="checkbox"]:checked + label {
        color: $light-green;
        .yes {
          display: inline;
        }
        .no {
          display: none;
        }
      }
      input[type="checkbox"]:checked + label:after {
        margin-left: 16px;
      }
      input[type="checkbox"]:checked + label:before {
        background: $light-green;
      }

      .user {
        position: relative;
      }
      &.locked {
        .user {
          padding-left: 2.5 * $font-size-base;
          &:before {
            content: "\00a0";
            position: absolute;
            left: $font-size-base;
            top: 50%;
            transform: translateY(-50%);
            color: theme-color('light');
            color: #6c757d; // FIXME theme-color('muted') does not expand
            padding-left: 1.4rem;
            background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iOHB4IiBoZWlnaHQ9IjExcHgiIHZpZXdCb3g9IjAgMCA4IDExIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IHNrZXRjaHRvb2wgNDguMiAoNDczMjcpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPjI5MDJFMjM3LTE0RTktNEQzQS05MjFELTkyODlENkVDQkU5MTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggc2tldGNodG9vbC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iYXYwM0MyX0NyZWF0ZV9zdGFydHVwX2ZpbGxlZCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEwODYuMDAwMDAwLCAtNzEzLjAwMDAwMCkiIG9wYWNpdHk9IjAuNjAwMDAwMDI0Ij4KICAgICAgICA8ZyBpZD0iQ29udGVudCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzIwLjAwMDAwMCwgMTIwLjAwMDAwMCkiPgogICAgICAgICAgICA8ZyBpZD0ic3RhcnR1cF9pbmZvcyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC4wMDAwMDAsIDY4LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9InJvdyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzAuMDAwMDAwLCA0NjcuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTE0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3MTYuMDAwMDAwLCAzOS4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICAgICAgPGcgaWQ9Imljbl9pbnB1dF9sb2NrZWQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIwLjAwMDAwMCwgMTYuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlIiBmaWxsPSIjOTc5REFEIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHg9IjAiIHk9IjgiIHdpZHRoPSI4IiBoZWlnaHQ9IjYiIHJ4PSIxIj48L3JlY3Q+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMiw5IEwyLDYgQzIsNC44OTU0MzA1IDIuODk1NDMwNSw0IDQsNCBMNCw0IEM1LjEwNDU2OTUsNCA2LDQuODk1NDMwNSA2LDYgTDYsOSIgaWQ9IlJlY3RhbmdsZS0yIiBzdHJva2U9IiM5NzlEQUQiIHN0cm9rZS13aWR0aD0iMiI+PC9wYXRoPgogICAgICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=') 0 30% /auto 1rem no-repeat;
          }
        }
      }
    }

    .document-select-widget {
      position: relative;

      .dropdown-toggle {
        outline: none !important;
        text-decoration: none !important;
        position: relative;
        cursor: pointer;

        .documents-list {
          display: flex;
          flex-wrap: wrap;
        }

        &:after {
          display: inline-block;
          margin-left: 0.255em;
          vertical-align: 0.255em;
          content: "";
          border-top: 0.3em solid;
          border-right: 0.3em solid transparent;
          border-bottom: 0;
          border-left: 0.3em solid transparent;
          background-color: white;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 30px;
        }
        &[value=''] {
          font-style: italic;
        }
      }
      .dropdown-menu {
        width: 100%;
        overflow-x: hidden;
      }
      a.dropdown-item[value=''] {
        font-style: italic;
      }
    }

  }


}