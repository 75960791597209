.document-viewer {
  &.modal {
    padding: 0 !important; // override inline padding-right added from js
    .modal-dialog {
      width: 96%;
      max-width: none;
      height: calc(100% - 20px);
      margin: 10px auto;
    }
    .modal-content {
      height: 100%;
      .modal-body {
        display: flex;
        object {
          flex: 1 0 100%;
        }
      }
    }
  }
}
