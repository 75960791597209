#savedSearches {
    .table-container {
        &.hidden {
            opacity: 0;
        }
        #savedSearchesTable {
            min-height: 300px;

            .row.selected {
                background-color: theme-color-level('primary', -8) !important;
            }

            .cell[index="2"] {
                .content {
                    margin: auto;
                }
            }
        }        
    }

}