.page.home .metrics {

  > div {
    .label {
      font-size: $font-size-base * 1.2;
    }
    .count {
      font-size: $font-size-base * 1.8;
    }
  }

}