.documents-uploader {
    .modal-body {
      input#documentsFileInput {
        display: none;
      }
  
      .document-to-upload {
        a {
          text-decoration: underline;
        }
      }
    }
  }

  .document-drop-area {
    &.dragover {
      outline: 3px dashed orange;
      outline-offset: -2px;
    }
  }