// customizing bootstrap elements

h1 {
  font-size: $font-size-base * 1.7;
  margin: $spacer * 1.1 0;
  color: $color-dark-blue;

  &.entity {
    margin: $spacer * 1.0 0;
    font-size: $font-size-base * 1.5; 
  }
}

h2 {
  color: $color-dark-blue;
}

// common elements

.tag-pill {
  background-color: $bg-color-faded-blue;
  color: $color-dark-blue;
  border-radius: 1rem;
  padding: $font-size-base * 0.2 $font-size-base * 0.4;
  margin-right: $font-size-base * 0.5;
  margin-top: $font-size-base * 0.2;
  margin-bottom: $font-size-base * 0.2;
  border: 1px solid transparent;
  display: inline-block;
  white-space: nowrap;
}

.document {
  background-color: white;
  color: $color-dark-blue;
  border-radius: .2rem;
  padding: $font-size-base * 0.2 $font-size-base * 0.4;
  margin-right: $font-size-base * 0.5;
  margin-top: $font-size-base * 0.2;
  margin-bottom: $font-size-base * 0.2;
  border: 1px solid theme-color('primary');
  display: inline-block;
  white-space: nowrap;

  &:before {
    content: "";
    width: 14px;
    height: 16px;
    display: inline-block;
    background: no-repeat url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTVweCIgaGVpZ2h0PSIxN3B4IiB2aWV3Qm94PSIwIDAgMTUgMTciIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUzLjIgKDcyNjQzKSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT5Db21iaW5lZCBTaGFwZTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJVSSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkF2Ml9pbnZlc3Rvcl9wYWdlX0FSQ0hJVkUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00ODAuMDAwMDAwLCAtMTEyLjAwMDAwMCkiIGZpbGw9IiM0QzdERkYiPgogICAgICAgICAgICA8ZyBpZD0iRG9jcyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDY4LjAwMDAwMCwgMTAxLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTE4Ljc1LDExLjcwODMzMzMgQzE4Ljc1LDExLjMxNzMzMzMgMTkuMDg2LDExIDE5LjUsMTEgTDI2LjI1LDExIEMyNi42NjQ3NSwxMSAyNywxMS4zMTczMzMzIDI3LDExLjcwODMzMzMgTDI3LDI3LjI5MTY2NjcgQzI3LDI3LjY4MzM3NSAyNi42NjQ3NSwyOCAyNi4yNSwyOCBMMTIuNzUsMjggQzEyLjMzNiwyOCAxMiwyNy42ODMzNzUgMTIsMjcuMjkxNjY2NyBMMTIsMTguMDgzMzMzMyBDMTIsMTcuNjkyMzMzMyAxMi4zMzYsMTcuMzc1IDEyLjc1LDE3LjM3NSBMMTgsMTcuMzc1IEMxOC40MTQsMTcuMzc1IDE4Ljc1LDE3LjA1ODM3NSAxOC43NSwxNi42NjY2NjY3IEwxOC43NSwxMS43MDgzMzMzIFogTTE1LjM4OTIxMDUsMTEuNTQ2MzE4MiBDMTUuODg2NTc4OSwxMS4wNTk1IDE2LjczNjg0MjEsMTEuNDA0MTM2NCAxNi43MzY4NDIxLDEyLjA5MjYzNjQgTDE2LjczNjg0MjEsMTQuODYzNjM2NCBDMTYuNzM2ODQyMSwxNS4yOTAxODE4IDE2LjM4MzE1NzksMTUuNjM2MzYzNiAxNS45NDczNjg0LDE1LjYzNjM2MzYgTDEzLjExNjMxNTgsMTUuNjM2MzYzNiBDMTIuNDEyODk0NywxNS42MzYzNjM2IDEyLjA2MDc4OTUsMTQuODA0MTM2NCAxMi41NTgxNTc5LDE0LjMxNzMxODIgTDE1LjM4OTIxMDUsMTEuNTQ2MzE4MiBaIiBpZD0iQ29tYmluZWQtU2hhcGUiPjwvcGF0aD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+') 0 0 /100% 100%;
    position: relative;
    margin-right: 3px;
    top: 2px;
  }

  &.deleted {
    border-color: theme-color('danger');
    background-color: rgba(theme-color('danger'), .4);
    opacity: 0;
  }
}
a.document {
  text-decoration: none !important;
  transition: opacity 2s ease;

  &:hover {
    border-color: theme-color('secondary');
  }
}


.btn i img {
  height: 1rem;
  width: auto;
  margin-right: .2rem;
}

.token {
  background-color: $bg-color-medium-blue;
  color: white;
  margin: 1px 1px;
  padding: .3 * $font-size-base .4 * $font-size-base;
  white-space: nowrap;
}

a {
  &.external-link {
    color: #007bff;
  }
}

.with-right-separator {
  &:after {
    content: '|';
    color: #001950;
    display: inline-block;
    padding: 0 .2rem;
  }
}

.archived-mark {
  display: inline-block;
  margin-left: 1rem;
  text-transform: uppercase;
}