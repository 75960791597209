html {
  margin: 0;
  padding: 0;
  position: fixed;
}

body {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  justify-content: stretch;
  align-items: stretch;
  font-family: $font-family-sans-serif;
  font-size: 14px;
  width: 100vw;
  height: 100vh;

  .page-wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: stretch;
    align-items: stretch;
    height: 100vh;
    background-color: $bg-color-light;
    flex: 1 1 100%;

    main {
      flex: 1 1 100%;
    }
  }
}

// data layout

.data-block {
  background-color: white;

  &-section {
    border-top: 1px solid #E8EBF5; 
    &:first-child {
      border-top: none;
    }
  }
}

.data-label {
  font-size: $font-size-base * 0.8;
}

.active {
  display: block !important;
}