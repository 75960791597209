.revenue-table {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: theme-color('primary');
  
  .table-header {
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    box-shadow: 0 5px 6px -4px rgba(196,200,213,0.4);
    > * {
      flex: 0 0 20%;
      font-weight: bold;
      padding: $padding/2 $padding;
      text-align: right;
      &.amount, &.source {
        flex-basis: 20%;
      }
    }
  }
  .table-content {
    overflow: auto;
    .row {
      margin: 0;
      display: flex;
      flex-direction: row;
      flex: 0 0 auto;
      border-bottom: 1px solid #E8EBF5;
      &:hover {
        background-color: rgb(246,248,254);
      }
      > * {
        flex: 0 0 20%;
        padding: $padding/2 $padding;
        text-align: right;
        &.year {
          font-weight: 500;
        }
        &.amount, &.source {
          flex-basis: 20%;
        }
        &.documents {
          a.document {
            opacity: .8;
            &:hover {
              text-decoration: none;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}