.messages-container {
    pointer-events: none;
}

.messages {
    top: 0;
    z-index: 1050;

    .message {
        &.success {
            background-color: theme-color-level('success', -10) !important;
        }
        &.warning {
            background-color: theme-color-level('warning', -10) !important;
        }
        &.error {
            background-color: theme-color-level('danger', -10) !important;
        }

        opacity: 1;
        transition: opacity ease-in-out 1s;
        &.faded {
            opacity: 0;
        }
    }
}
