menu {
  width: 60px;
  flex: 0 0 auto;
  background-color: $bg-color-dark-blue;
  margin: 0; padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;

  a {
    width: 52px;
    height: 45px;
    border-radius: 8px;
    margin: 12px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &.active {
      background-color: $bg-color-light-blue;
      display: flex !important;
    }
  }
}