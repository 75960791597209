.ttm-chart-block {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .ttm-chart-container {

    .ttm-chart-wrapper {
      position: relative;
      min-height: 300px;
      width: 100%;
      height: 100%;
    }
  }

  .ttm-chart-legend {
    display: flex;
    flex-direction: row;
    align-items: center;

    .trust-score-bar {
      height: 10px;
      flex-grow: 1;
      flex-shrink: 1;
      background: linear-gradient(to left, #29407a, #abbbe3);
    }
  }
}


.ttm-chart-tooltip {
  transform: translateX(-50%);
  pointer-events: none;
  transition: opacity .6s ease;
  z-index: 100;

  .chart-tooltip-tip {
    margin: auto;
    border-bottom: none;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: $bg-color-light-blue;
    z-index: 1;
  }

  .chart-tooltip-content {
    width: 190px;
    border-radius: 3px;
    background-color: $bg-color-light-blue;
    box-shadow: 0 2px 4px 0 rgba(48,72,150,0.2);
    padding: 1rem;

    p {
      margin-bottom: .2rem;
    }

    p.year {
      color: white;
      font-size: 1rem;
      font-weight: 400;
      text-align: center;
      margin-bottom: 0;
    }

    p.revenue {
      color: white;
      font-size: 1.4rem;
      font-weight: 400;
      text-align: center;
      margin-bottom: 4px;
    }

    div.trust-score,
    div.source {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: .8rem;
      color: white;
      font-weight: 400;
    }

  }

}