// COLORS
$bg-color-light: rgb(246,248,255);
$bg-color-medium-blue: #283F79;
$bg-color-dark-blue: #001950;
$bg-color-light-blue: #4C7DFF;
$bg-color-faded-blue: #f0f3fc;
$bg-color-orange: #FF5A2F;

$color-dark-blue: #001950;
$color-light-blue: #4C7DFF;
$color-faded-blue: #6F82B3;
$color-light-green: #32C642;
$color-light-gray: #979DAD;

// customizing bootstrap

$theme-colors: (
  "primary": $color-dark-blue,
  "secondary": $color-light-blue,
  "light": $color-faded-blue
);

// all below is deprecated

// GLOBAL (DEPRECATED)
$main-padding: 3rem;
$button-border-radius: 4px;

// GLOBAL
$margin-h: 4rem;
$margin-v: .5rem;
$spacing-h: 2rem;
$spacing-v: 1rem;
$padding: 1rem;

// COLORS (DEPRECATED)
$body-color: #333333;
$white: #fff;
$light-gray: #F5F6FA;
$text-light-gray: #979DAD;
$orange: #FF5A2F;
$menu-dark-blue: #283F79;
$menu-light-blue: #E9ECF4;
$text-dark-blue: #283F79;
$text-light-blue: #6F82B3;
$text-orange: #FF5A2F;
$light-red: #e69797;
$pure-red: #E66767;
$light-green: #8FD083;
$pure-green: #49bc36;

// SIZES
$top-bar-height: 80px;

// TYPOGRAPHY
$font-family-sans-serif: 'Open Sans', sans-serif;
$font-family-sans-serif: 'Inter UI', sans-serif;
$h1-font-size: 3rem;
$h2-font-size: 1.3rem;
$h3-font-size: 1rem;
