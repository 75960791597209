ul.searchbar-autocomplete.ui-autocomplete {
  z-index: 3000;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #DDDFE7;
  box-shadow: 0 5px 15px -5px rgba(48,72,150,0.2);
  width: auto;

  li {
    color: $text-light-blue;
    padding: 0;
    border-bottom: 1px solid #E8EBF5;
    font-weight: 500;
    &:last-child {
      border-bottom: none;
    }

    div {
      padding: 1rem 2rem;

      &.ui-state-active {
        margin: inherit;
        border: none;
        background: inherit;
        font-weight: inherit;
        color: inherit;
        background-color: $menu-light-blue;
      }
      .name {
        display: block;
        font-size: 1rem;
        .term {
          color: $color-dark-blue;
          font-weight: 600;
        }
      }
      .type {
        display: inline-block;
        text-transform: uppercase;
        color: $color-dark-blue;
        font-weight: 500;
        font-size: .7rem;
        padding-left: 12px;
        margin-right: 20px;
        &[value="investor"] {
          background: url("../images/smallicn_investor.svg") no-repeat 0px 3px / 10px 10px;
        }
        &[value="startup"] {
          background: url("../images/smallicn_startup.svg") no-repeat 0px 3px / 10px 10px;
        }
        &[value="advisor"] {
          background: url("../images/smallicn_advisor.svg") no-repeat 0px 3px / 10px 10px;
        }
      }
      .tags {
        display: inline-block;
        color: $color-dark-blue;
        font-weight: 400;
        font-size: .8rem;
        padding-left: 12px;
        background: url("../images/smallicn_tag.svg") no-repeat 0px 5px / 10px 10px;
      }
    }

    &.search-bar-noresults {
      text-align: center;
      box-shadow: none;
      .ui-state-active {
        background-color: inherit;
      }
    }
  }

}

.ui-menu.ui-autocomplete.searchbar-autocomplete {
  max-height: 50vh;
  overflow: auto;
}
